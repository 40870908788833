header {
  position: relative;
  min-width: 1280px;
  border-bottom: 1px solid var(--border-light);

  .logo {
    margin-right: 36px;
    width: 190px;

    a {
      display: block;
      height: 37px;
      background: url('../../../assets/images/common/logo.svg') no-repeat center/ contain;
      font-size: 0;
      text-indent: -9999rem;
    }
  }

  nav {
    ul.depth1 {
      position: relative;
      display: flex;
      flex-direction: row;

      > li {
        position: relative;
        > a {
          position: relative;
          padding: 24px 48px;
          min-width: 210px;
          color: var(--font-color-darken);
          text-align: center;

          &:hover,
          &:focus,
          &.active {
            color: var(--font-point);

            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 3px;
              background-color: var(--color-primary);
            }
          }
        }
      }

      .depth2 {
        position: absolute;
        top: 112px;
        left: 0;
        z-index: 10;

        .sub-menu1 {
          left: 0;
        }
        .sub-menu2 {
          left: 210px;
        }


        li {
          a {
            min-width: 210px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: var(--space-2);
            height: 60px;
            color: var(--font-darken);

            &:hover,
            &:focus,
            &.active {
              border: 0 none;
              overflow: hidden;
              border-radius: var(--radius-1);
              background-color: #d4e8ff;
              font-weight: var(--font-weight-bold);
            }
          }
        }
      }
    }
  }

  .menu-wrap {
    position: absolute;
    top: 100%;
    width: 100%;
    height: 300px;
    border-top: 1px solid var(--border-light);
    border-bottom: 1px solid var(--border-light);
    background-color: var(--bg-white);
    opacity: 0;
    transform: scaleY(0);
    transform-origin: 0 0;
    transition: all 250ms cubic-bezier(0.17, 0.67, 0.6, 0.93);
    z-index: 1;

    &.open {
      opacity: 1;
      transform: scaleY(1);
    }

  }
}

.skipNavi {
  a {
    display: block;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: -10000px;

    &:focus {
      background-color: var(--bg-darken);
      color: var(--font-white);
      padding: 1rem 0;
      text-align: center;
      top: 0;
      width: 100%;
      z-index: 100;
    }
  }
}