/* .local에 지역명을 class로 입력시 해당 지역의 색이 들어갑니다. 
    강원: gangwon, 경기: gyeonggi, 경남: gyeongnam, 경북: gyeongbuk, 광주: gwangju,
    대구: daegu, 울산: ulsan, 인천: incheon, 전남: jeonnam, 전북: jeonbuk,
    제주: jeju, 충남: chungnam , 충북: chungbuk
*/
.local-map {
  .local {
    cursor: pointer;
    svg {
      &:focus {
        // outline: none;
        //outline: white;
      }
    }
    &.incheon,
    &.chungbuk,
    &.daegu,
    &.ulsan,
    &.gwangju {
      &:hover {
        path {
          fill: #499dd8;
        }
      }
      path {
        fill: #2d8dd2; //map5
      }
    }
    &.gyeonggi,
    &.gyeongnam {
      &:hover {
        path {
          fill: #e3f1f9;
        }
      }
      path {
        fill: #badbf0; //map1
      }
    }
    &.gangwon,
    &.jeonbuk {
      &:hover {
        path {
          fill: #badbf0;
        }
      }
      path {
        fill: #8fc4e7; //map2
      }
    }
    &.chungnam {
      &:hover {
        path {
          fill: #67addd;
        }
      }
      path {
        fill: #499dd8; //map4
      }
    }
    &.gyeongbuk,
    &.jeonnam,
    &.jeju {
      &:hover {
        path {
          fill: #8fc4e7;
        }
      }
      path {
        fill: #67addd; //map3
      }
    }
    &.active {
      &:hover {
        path {
          fill: var(--color-primary);
        }
        .point {
          circle {
            stroke-width: 2;
          }
        }
      }
      path {
        fill: var(--color-primary);
      }
      .point {
        stroke: #43b89b;
        circle {
          stroke-width: 2;
        }
      }
    }
    &:hover {
      .point {
        circle {
          stroke-width: 3;
        }
      }
    }
    .point {
      stroke: var(--color-primary);
      stroke-width: 2;
    }
  }
}
