.board-text-items {
  .rt-TableRoot {
    --table-cell-min-height: var(--space-18);
    border-inline: none;
    border-block-start: 2px solid var(--gray-12);

    .rt-TableHeader {
      font-size: var(--font-size-5);
      font-weight: var(--font-weight-bold);
    }

    .rt-TableBody {
      font-size: var(--font-size-4);
      .rt-TableRow {
        color: var(--font-basic);
        &.row-notice {
          background-color: var(--bg-blue);
          color: var(--font-darken);
          font-weight: var(--font-weight-bold);
        }

        .rt-TableCell {
          a {
            &:visited,
            &:hover {
              color: var(--font-color-darken);
            }
          }
        }
      }
    }

    .rt-TableColumnHeaderCell {
      position: relative;
      &:not(:first-child)::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 24px;
        background-color: var(--border-light);
        transform: translateY(-50%);
      }
    }

    [data-title="제목"] {
      cursor: pointer;
    }


  }
}
