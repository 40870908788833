.radix-themes {
  .rt-IconButton {
    &.rt-r-size-1 {
      --base-button-height: var(--space-6);
    }
    &.rt-r-size-2 {
      --base-button-height: var(--space-12);
    }
    &.rt-variant-text {
      aspect-ratio: 1 / 1;
      box-sizing: border-box;
      height: var(--base-button-height);
      background-color: transparent;
      color: var(--font-basic);
      svg {
        color: inherit;
      }
      &:hover:not(:disabled) {
        color: var(--accent-12);
      }
    }
    &.rt-variant-outline {
      box-shadow: none;
      border: 1px solid var(--border-darken);
      color: var(--accent-12);
      &:hover:not(:disabled) {
        background-color: var(--bg-light);
      }
    }
    &:disabled {
      pointer-events: none;
    }
  }
}
