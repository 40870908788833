.local-center {
  h4 {
    margin-bottom: 24px;
  }

  .outlined {
    border: 1px solid var(--border-light);
  }

  .bold {
    font-weight: var(--font-weight-bold);
  }

  .icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &.center {
      background-image: url('../../assets/images/icon/icon-school-2.svg');
    }

    &.organization-chart {
      background-image: url('../../assets/images/icon/icon-organization-chart.svg');
    }

    &.map {
      background-image: url('../../assets/images/icon/icon-location.svg');
    }

    &.local {
      width: 152px;
      height: 110px;
      font-size: 0;
      text-indent: -9999rem;
    }
  }

  .center-logo {
    width: auto;
    height: 80px;
    img {
      width: auto;
      height: 100%;
      object-fit: contain;
    }
  }

  .tabslist {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray-a5);

    li {
      flex: 1 1 30%;
      height: auto;
      border-width: 1px 1px 0;
      border-style: solid;
      border-color: var(--gray-a5);
      border-radius: var(--radius-4) var(--radius-4) 0 0;
      font-weight: var(--font-weight-bold);
      cursor: pointer;

      a {
        display: block;
        width: 100%;
        height: auto;

        padding: var(--space-5) 0;
        border-radius: var(--radius-4) var(--radius-4) 0 0;

        &.rt-BaseTabListTrigger:where([data-state='active'], &[data-active])::before {
          background-color: transparent;
        }

        &.rt-BaseTabListTrigger:where([data-active]) {
          background-color: var(--color-primary);

          span {
            color: #fff;
          }
        }

        &:hover {
          background-color: var(--color-primary);

          span {
            color: #fff;
          }
        }

        span {
          width: 100%;
          text-align: center;
          color: var(--font-basic);
        }
      }
    }
  }

  .info-items {
    padding: 0 var(--space-5);
    display: flex;
    flex-wrap: wrap;
    &.info-items-block {
      display: block;
      li {
        margin-bottom: var(--space-6);
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    li {
      flex: 0 0 50%;
      position: relative;
      margin-bottom: var(--space-6);
      padding-left: var(--space-8);

      &:first-of-type {
        margin-top: 0;
      }

      &::before {
        content: '';
        position: absolute;
        top: 2px;
        left: 0;
        width: 24px;
        height: 24px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      &.address {
        &::before {
          background-image: url('../../assets/images/icon/icon-map.svg');
        }
      }

      &.call {
        &::before {
          background-image: url('../../assets/images/icon/icon-phone.svg');
        }
      }

      &.time {
        &::before {
          background-image: url('../../assets/images/icon/icon-time.svg');
        }
      }

      &.mail {
        &::before {
          background-image: url('../../assets/images/icon/icon-message.svg');
        }
      }

      &.member {
        &::before {
          background-image: url('../../assets/images/icon/icon-member.svg');
        }
      }

      span {
        display: inline-block;
        padding-left: var(--space-2);
      }
    }

    .title {
      display: inline-block;
      position: relative;
      margin-right: calc(var(--space-13) - 2px);
      min-width: 90px;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -21px;
        width: 1px;
        height: 24px;
        background-color: var(--border-light);
        transform: translateY(-50%);
      }
    }
  }

  .info-wrap {
    padding: 0;
    width: 400px;

    &.light-blue {
      strong {
        background-color: var(--bg-sky);
      }
    }

    &.light-yellow {
      strong {
        background-color: var(--bg-light-yellow);
      }
    }

    &.light-red {
      strong {
        background-color: var(--bg-light-red);
      }
    }

    &.light-green {
      strong {
        background-color: var(--bg-green);
      }
    }

    &.light-purple {
      strong {
        background-color: var(--bg-purple);
      }
    }

    strong {
      display: block;
      padding: var(--space-8);
      text-align: center;
    }

    .info-items {
      padding: var(--space-8);
    }
  }

  .local-business {
    .text-wrap {
      padding: var(--space-15) var(--space-15) var(--space-10);

      h5 {
        margin-bottom: var(--space-10);
      }

      p {
        margin-bottom: var(--space-10);
      }
    }

    .image-wrap {
      flex-shrink: 0;
    }
  }

  .dots {
    li {
      position: relative;
      padding-left: var(--space-4);
      margin-top: var(--space-4);
      color: var(--font-basic);

      &::before {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        transform: translateY(0);
        background-color: var(--elements-blue);
      }
    }
  }

  .mission {
    position: relative;
    margin-top: var(--space-23);
    padding: var(--space-30) var(--space-9) var(--space-9);
    width: 560px;
    border-radius: var(--radius-4);
    box-shadow: var(--shadow-3);

    &.scheme-blue {
      border: 1px solid var(--elements-blue);

      h4 {
        border-color: var(--sw-bg-line-blue);
        background-color: var(--elements-blue);
      }
    }

    &.scheme-green {
      border: 1px solid var(--elements-green);

      h4 {
        border-color: var(--sw-bg-line-green);
        background-color: var(--elements-green);
      }
    }

    h4 {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 50%;
      width: 180px;
      height: 180px;
      border-width: 12px;
      border-style: solid;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }

    p {
      color: var(--font-basic);
      text-align: center;

      span {
        color: var(--accent-9);
      }
    }
  }

  .circlebox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    row-gap: calc(var(--space-3) - 2px);
    margin-left: var(--space-18);
    margin-bottom: calc(var(--space-10) - 1px);
    width: 180px;
    height: 180px;
    border-radius: 50%;
    box-shadow: var(--shadow-3);

    &:first-of-type {
      margin-left: 0;

      &::after {
        content: none;
      }
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -39px;
      left: 50%;
      width: 63px;
      height: 15px;
      border-radius: 50%;
      transform: translateX(-50%);
    }

    &.red {
      &::before {
        background-color: var(--sw-orange);
      }
    }

    &.yellow {
      &::before {
        background-color: var(--sw-yellow);
      }
    }

    &.green {
      &::before {
        background-color: var(--sw-green);
      }
    }

    &.blue {
      &::before {
        background-color: var(--sw-light-blue);
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 90px;
      left: -72px;
      width: 72px;
      height: 1px;
      border-top: 1px dashed var(--border-basic);
    }

    h5 {
      margin-bottom: 0;
    }
  }

  .local-info {
    padding: var(--space-20) var(--space-10);
    background-color: var(--bg-blue);
    text-align: center;

    h4 {
      margin-bottom: calc(var(--space-13) - 2px);
    }
  }

  .slider-wrap {
    position: relative;
    margin-top: var(--space-20);
    padding-bottom: var(--space-20);
    border-bottom: 1px solid var(--border-light);

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .text-wrap {
      margin-top: var(--space-10);
      padding-top: var(--space-10);
      border-top: 1px solid var(--border-darken);

      .bold {
        margin-bottom: var(--space-4);
        color: var(--font-darken);
      }
    }
  }

  .slider-text {
    flex-shrink: 0;
    width: 354px;

    p {
      color: var(--font-basic);
      word-break: break-all;
    }
  }

  .center-info {
    .image-wrap {
      margin-bottom: var(--space-15);
    }

    .title4 {
      margin-bottom: var(--space-3);
      color: var(--font-darken);
    }

    .body1 {
      color: var(--font-light);
    }
  }

  .slider-indicator {
    margin-top: var(--space-10);

    span {
      color: var(--font-basic);
    }

    .total {
      position: relative;
      padding-left: var(--space-4);
      opacity: 0.64;

      &::before {
        content: '/';
        position: absolute;
        bottom: 0;
        left: 4px;
      }
    }
  }

  .table-wrap {
    &.line {
      border-top: 1px solid var(--border-light);
    }

    h5 {
      margin-bottom: var(--space-6);
    }

    .data-table {
      th,
      td {
        border-left: 1px solid var(--border-light-blue);
        box-shadow: inherit;

        &:first-of-type {
          border-left: 0;
        }
      }

      th {
        border-top: 2px solid var(--border-darken);
        border-bottom: 1px solid var(--border-light-blue);
        background-color: var(--bg-light-blue);
      }

      td {
        border-bottom: 1px solid var(--border-light);
      }
    }
  }

  .map-wrap {
    &:nth-of-type(n + 2) {
      border-top: 1px solid var(--border-light);
    }
    &.no-border {
      border-top: none;
    }

    .title4 {
      margin-bottom: var(--space-6);
    }

    .regular {
      font-weight: var(--font-weight-regular);
    }

    p {
      &:nth-of-type(n + 2) {
        margin-top: var(--space-2);
      }
    }

    .extra {
      margin-left: auto;

      span {
        color: var(--font-error);
      }
    }

    .icon {
      position: relative;
      padding-left: var(--space-12);

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 36px;
        height: 36px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        transform: translateY(-50%);
      }

      &.car {
        &::before {
          background-image: url('../../assets/images/icon/icon-car.svg');
        }
      }

      &.bus {
        &::before {
          background-image: url('../../assets/images/icon/icon-bus.svg');
        }
      }

      &.subway {
        &::before {
          background-image: url('../../assets/images/icon/icon-subway.svg');
        }
      }
    }
  }

  .empty {
    display: flex;
    justify-content: center;
    padding-top: 330px;
    font-size: 18px;
    width: 100%;
    height: 500px;
    background: #f8f8f8 url('../../assets/images/common/no-image.png') no-repeat center center;
  }
}
