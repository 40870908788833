.radix-themes {
  .rt-SelectTrigger {
    width: 100%;
    box-shadow: none;
    border: 1px solid var(--border-light);
    &.rt-r-size-2 {
      --select-trigger-height: var(--space-12);
    }
    &[data-state="open"] {
      border-end-start-radius: 0;
      border-end-end-radius: 0;
    }
    &:hover {
      border-color: var(--border-darken);
    }
  }

  .rt-SelectIcon {
    width: var(--font-size-2);
    height: var(--font-size-2);
    fill: var(--gray-12);
  }
}
/* items .radix-themes에서 분리 */
.rt-SelectContent {
  // background-color: red;
  --select-content-padding:var(--space-1);
  --select-item-height: var(--space-12);
  --select-item-indicator-width: var(--space-3);
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  box-shadow: none;
  border: 1px solid var(--border-light);
  border-block-start: none;
}

.rt-SelectItem {
  border-radius: 0;
  font-size: var(--font-size-3);
  color: var(--font-basic);
  background-color: var(--bg-white);
  &[data-state="checked"] {
    color: var(--font-color-point);
    font-weight: 700;
  }
  &:hover , &:focus{
    color: var(--font-darken);
    background-color: var(--bg-light);
    z-index: 2;
  }
}
