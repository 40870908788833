footer {
  // margin-top: var(--space-35);
  min-width: 1280px;
  background-color: var(--bg-light);

  address {
    margin-top: var(--space-4);
    color: var(--font-color-basic);
    font-style: normal;
  }
  .text-wrap {
    padding: var(--space-6) 0;
  }
  .logo {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    gap: var(--space-5);
  }
  .wa{
    height: 52px;
  }
  .swedu{
    content: "";
    display:inline-block;
    width: 184px;
    height: 36px;
    background: url('../../../assets/images/common/logo-gray.svg') no-repeat center/ contain;
    font-size: 0px;
    text-indent: -9999rem;
  }

  .policy {
    a {
      display: inline-block;
      position: relative;
      margin-left: var(--space-12);
      color: var(--font-light);
      &:first-of-type {
        margin-left: 0;
        &::before {
          content: none;
        }
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: calc(var(--space-6) * -1);
        width: 1px;
        height: 25px;
        background-color: var(--border-light);
        transform: translateY(-50%);
      }
    }
  }
  .copyright {
    margin-top: var(--space-1);
    color: var(--font-color-light);
  }
}
