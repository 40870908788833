.pagination {
  .rt-IconButton {
    &.rt-variant-outline {
      color: var(--icon-light);
      border-color: var(--border-light);

      &:hover:not(:disabled) {
        background-color: transparent;
        color: var(--accent-12);
        border-color: var(--border-darken);
      }
    }
    &.rt-variant-text {
      margin: 0;
      color: var(--font-light);
      width: 48px;
      height: 48px !important;
      border-radius: 0;
      &.currentPage {
        color: var(--accent-12);
        border-bottom: 2px solid var(--accent-12);
      }
    }
    &:disabled {
      background: var(--bg-light);
    }
  }
}
