.visual {
  min-width: var(--container-4);
  width: 100%;
  height: 320px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  &.main {
    height: 400px;
    background-image: url('../../../assets/images/common/img-visual-main.svg');
    .title2 {
      padding-top: var(--space-25);
    }
    .image-wrap {
      margin-top: var(--space-3);
      width: 288px;
      img {
        width: 100%;
      }
    }
  }
  &.introduction {
    margin-bottom: 0;
    background-image: url('../../../assets/images/common/img-visual-intro.svg');
  }
  &.privacy {
    background-image: url('../../../assets/images/common/img-visual-privacy.svg');
  }
  &.introduction {
    margin-bottom: 0;
    background-image: url('../../../assets/images/common/img-visual-intro.svg');
  }
  &.education {
    background-image: url('../../../assets/images/common/img-visual-edu.svg');
  }
  &.event {
    margin-bottom: 0;
    background-image: url('../../../assets/images/common/img-visual-event.svg');
  }
  &.notice {
    background-image: url('../../../assets/images/common/img-visual-noti.svg');
  }
  &.promotion {
    background-image: url('../../../assets/images/common/img-visual-promotion.svg');
  }
  h2 {
    padding-top: var(--space-22);
  }
}
