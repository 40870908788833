.business {
    h4 {
        margin-bottom: 24px;
    }

    .ci-wrap {
        padding: var(--space-15) var(--space-10);
        border-radius: var(--radius-4);
        border: 1px solid var(--border-light);

        span {
            color: var(--font-point);
        }
    }

    .rule {
        .box-outline {
            border-radius: var(--radius-4);
            border: 1px solid var(--border-light);
        }
    }

    .color {
        p {
            margin-bottom: var(--space-4);
        }
        .subcolor {
            gap: var(--space-7);
        }
        .swatch {
            width: 218px;
            border-radius: var(--radius-4);
            box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.1);

            &.dark-blue {
                background-color: var(--sw-dark-blue);
            }

            &.sw-orange {
                background-color: var(--sw-orange);
            }

            &.sw-yellow {
                background-color: var(--sw-yellow);
            }

            &.sw-green {
                background-color: var(--sw-green);
            }

            &.sw-light-blue {
                background-color: var(--sw-light-blue);
            }

            &::before {
                content: '';
                display: block;
                height: 140px;
                border-bottom: 0;
                font-size: 0;
                margin-bottom: var(--space-3);
            }

            strong {
                display: block;
                background-color: var(--bg-white);
                padding: var(--space-3) var(--space-5);
            }

            span {
                display: block;
                padding: 0 var(--space-5) var(--space-5);
                background-color: var(--bg-white);
            }
        }
    }

    .ci-btn {
        width: 208px;
        height: 48px;
        border-radius: var(--space-8);
        padding: var(--space-3) var(--space-5) var(--space-3) var(--space-6);
        display: flex;
        align-items: center;
        background: var(--bg-darkness);
        margin-left: auto;
        span {
            font-size: var(--default-font-size);
            font-weight: var(--font-weight-bold);
            color: var(--font-white);
        }
        img {
            margin-left: auto;
        }
    }
}
