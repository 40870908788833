.post-title {
  padding-block: var(--space-10);
  border-block-start: 2px solid var(--gray-12);

  .chips.local.all {
    display: none;
  }

  .title3 {
    flex: 1;
    text-align: center;
  }
}

.post-info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--space-5);
  width: 100%;
  height: 52px;
  padding-inline: var(--space-9);
  background-color: var(--bg-light);
  color: var(--font-basic);
}

.post-box {
  padding: var(--space-12);
  margin-block-end: var(--space-7);
  border-block: 1px solid var(--border-light);

  // figure
  .image {
    img {
      height: auto;
    }
  }

  .post-image {
    margin-block-end: var(--space-12);
    text-align: center;
  }

  figure.table{
    table{
      border-collapse: collapse;
      max-width: 100%;
    }
    th, td{
      border: 1px solid var(--border-basic);
      padding:var(--space-1) var(--space-2);
    }
  }
}


.post-attachment {
  .attachment-title {
    display: flex;
    align-items: center;
    width: 120px;
    height: 64px;
    gap: var(--space-2);
    color: var(--font-darken);

    &.icon-clip {
      &::before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        background-image: url(../../../assets/images/icon/icon-clip.svg);
      }
    }

    &.icon-link {
      &::before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        background-image: url(../../../assets/images/icon/icon-link.svg);
      }
    }
  }
}

.attachment-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: var(--space-3);
  border: none;

  li {
    display: flex;
    align-items: center;
    gap: var(--space-3);
    padding-inline: var(--space-7);
    padding-block: var(--space-3);
    // width: 100%;
    width: 1148px;
    min-height: 64px;
    border: 1px solid var(--border-light);
    border-radius: var(--radius-4);

    a {
      width: 100%;
      display: flex;
      align-items: center;
      gap: var(--space-3);
      color: var(--font-darken);
      text-decoration: none;
      justify-content: space-between;
      p {
        width: 1000px;
        text-align: left;
        &.link {
          width: 100%;
        }
      }
    }

    &:hover {
      border-color: var(--color-primary);
      background-color: var(--bg-light);

      a {
        text-decoration: underline;
      }
    }

    .rt-BaseCheckboxRoot {
      --checkbox-size: var(--space-6);
      --checkbox-indicator-size: var(--font-size-3);

      &[data-state='checked']::before {
        background-color: transparent;
        border: 1px solid var(--accent-indicator);
      }

      .rt-BaseCheckboxIndicator {
        color: var(--color-primary);
        background-image: url(../../../assets/images/icon/icon-check.svg);
      }
    }

    .icon {
      display: inline-block;
      width: 36px;
      height: 36px;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
      }

      &.icon-pdf {
        &::before {
          background-image: url(../../../assets/images/icon/icon-pdf.svg);
        }
      }
      &.icon-xls {
        &::before {
          background-image: url(../../../assets/images/icon/icon-xls.svg);
        }
      }
      &.icon-png {
        &::before {
          background-image: url(../../../assets/images/icon/icon-png.svg);
        }
      }
      &.icon-jpg {
        &::before {
          background-image: url(../../../assets/images/icon/icon-jpg.svg);
        }
      }
      &.icon-jpeg {
        &::before {
          background-image: url(../../../assets/images/icon/icon-jpg.svg);
        }
      }
      &.icon-zip {
        &::before {
          background-image: url(../../../assets/images/icon/icon-zip.svg);
        }
      }
      &.icon-xslx {
        &::before {
          background-image: url(../../../assets/images/icon/icon-xls.svg);
        }
      }
      &.icon-doc,
      &.icon-docx {
        &::before {
          background-image: url(../../../assets/images/icon/icon-doc.svg);
        }
      }
      &.icon-hwp {
        &::before {
          background-image: url(../../../assets/images/icon/icon-hwp.svg);
        }
      }
      &.icon-hwpx {
        &::before {
          background-image: url(../../../assets/images/icon/icon-hwp.svg);
        }
      }
      &.icon-pptx {
        &::before {
          background-image: url(../../../assets/images/icon/icon-pptx.svg);
        }
      }
      &.icon-ppt {
        &::before {
          background-image: url(../../../assets/images/icon/icon-pptx.svg);
        }
      }
    }

    .download {
      margin-inline-start: auto;
      width: 24px;
      height: 24px;
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: url(../../../assets/images/icon/icon-download.svg) no-repeat center / contain;
      }
    }
  }
}

.post-navigation {
  margin-block-start: var(--space-12);
  border-block-start: 1px solid var(--border-darken);

  .item a {
    display: flex;
    align-items: center;
    gap: var(--space-4);
    height: 70px;
    padding-inline: var(--space-9);
    border-block-end: 1px solid var(--border-light);
    font-size: var(--font-size-4);
    color: var(--font-basic);
    cursor: pointer;

    &:hover {
      color: var(--font-darken);
      background-color: var(--bg-light);
    }

    a {
      flex-grow: 1;
      text-decoration: none;
      color: inherit;
    }
  }
}

.register {
  width: 110px;
  height: 48px;
  border-radius: var(--radius-6);
  font-size: var(--font-size-3);
  background-color: var(--accent-12);
}
