.main {
  h4 {
    margin-bottom: 24px;
  }
  .slider-wrap {
    position: relative;
    margin-top: var(--space-12);
  }
  .month-sorting {
    flex-shrink: 0;
    column-gap: var(--space-1);
    margin-left: var(--space-9);
    button {
      width: 40px;
      height: 40px;
      border: inherit;
      background-color: inherit;
      background-image: url('../../assets/images/icon/icon-circle-chevron-left.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      font-size: 0;
      box-shadow: inherit;

      &.next {
        right: 0;
        background-image: url('../../assets/images/icon/icon-circle-chevron-right.svg');
      }
    }
  }
  .rt-Link {
    &:hover {
      text-decoration-line: unset;
    }
  }
  .card {
    position: relative;
    padding: 0;
    &.image {
      width: 408px;
      .body {
        padding: var(--space-7);
      }
      .image-wrap {
        height: 230px;
        overflow: hidden;
        border-bottom: 1px solid var(--border-light);
        background: #f8f8f8 url('../../assets/images/common/no-image.png') no-repeat center center;
      }
      .title-2line {
        height: 64px;
      }
    }
    &.layer {
      margin-top: var(--space-41);
      width: 610px;
      border-radius: var(--radius-4);
      box-shadow: 0px 12px 28px 0px rgba(0, 0, 0, 0.12);
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: -40px;
        width: 268px;
        height: 224px;
        background: transparent url('../../assets/images/common/img-person.svg') no-repeat center/ contain;
        backdrop-filter: unset;
        box-shadow: none;
        transform: translateY(-100%);
      }
      p {
        color: var(--font-basic);
      }
      .body {
        padding: var(--space-9);
      }
      .image-wrap {
        width: 247px;

        img {
          width: 100%;
        }
      }
      .text-wrap {
        margin-top: var(--space-9);
        height: 116px;
      }
      .title5 {
        margin-bottom: var(--space-3);
        color: var(--font-darken);
      }
      .footer {
        display: flex;
        a {
          flex: 1 1 40%;
          position: relative;
          padding: var(--space-5) var(--space-16) var(--space-5) var(--space-9);
          border-top: 1px solid var(--border-light);
          border-radius: 0 0 var(--radius-4) var(--radius-4);
          background-color: var(--bg-light);
          color: var(--font-darken);
          text-align: left;
          &:nth-of-type(n + 2) {
            border-left: 1px solid var(--border-light);
            border-radius: 0 0 var(--radius-4) 0;
          }
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: var(--space-7);
            width: 24px;
            height: 24px;
            background: url('../../assets/images/icon/icon-arrow-right.svg') no-repeat center/ contain;
            transform: translateY(-50%);
          }
        }
      }
    }
    p {
      color: var(--font-darken);
      word-break: normal;
    }
    .chips {
      position: absolute;
      top: var(--space-3);
      right: var(--space-3);
    }
    .utility {
      margin-top: var(--space-5);
      padding-top: var(--space-5);
      border-top: 1px dashed var(--border-basic);
      color: var(--font-basic);
    }
    .view {
      position: relative;
      margin-left: auto;
      padding-left: var(--space-7);
      //   {/* [웹접근성] 조회수 아이콘 before -> img 태그로 변경 */}
      //   &::before {
      //     content: '';
      //     position: absolute;
      //     top: 0;
      //     left: -4px;
      //     width: 24px;
      //     height: 24px;
      //     background: url('../../assets/images/icon/icon-view.svg') no-repeat center/ contain;
      //   }
      .view-count {
        margin-left: 4px;
      }
    }
  }
  .notice-wrap {
    h3 {
      margin-bottom: var(--space-8);
    }
  }
  .notice {
    width: 844px;
    a {
      display: flex;
      align-items: center;
      column-gap: calc(var(--space-3) - 2px);
      padding: var(--space-5) var(--space-9);
      height: 92px;
      border-bottom: 1px solid var(--border-light);
      color: var(--font-darken);
      &:first-of-type {
        border-top: 2px solid var(--border-darken);
      }
      span {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .date {
      flex-shrink: 0;
      margin-left: auto;
    }
  }
  .buttons {
    flex-grow: 1;
    margin-left: var(--space-13);
    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: var(--space-4);
      margin-bottom: 0;
      padding: 0 var(--space-9);
      height: 156px;
      border: 1px solid var(--border-light);
      border-radius: var(--radius-4);
      background-color: var(--bg-light);
      color: var(--font-color-darken);
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.partners {
  padding: var(--space-12) 0;
  border-top: 1px solid var(--border-light);
  strong {
    flex-shrink: 0;
    margin-right: var(--space-12);
  }
}
