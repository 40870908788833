.radix-themes {
  .rt-TextFieldRoot {
    --text-field-focus-color: var(--color-primary);
    box-shadow: none;
    border: 1px solid var(--border-light);
    &.rt-r-size-2 {
      --text-field-height: var(--space-12);
    }
  }
}
