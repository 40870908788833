.chips {
        padding: var(--space-1) var(--space-6);
        border-radius: var(--radius-4);
        background-color: var(--bg-darken);
        color: var(--font-white);
        font-size: var(--default-font-size);
        font-weight: var(--font-weight-bold);
        line-height: calc(var(--default-font-size) * 1.5); // 24px
    
        &.local {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 72px;
            height: 72px;
            padding: 0;
            border-radius: 50%;
            background-color: var(--bg-white);
            color: var(--font-darken);
            font-size: var(--font-size-4);
            line-height: calc(var(--default-font-size) * 1.75); // 28px
        }
        // 강원: gangwon, 경기: gyeonggi, 경남: gyeongnam, 경북: gyeongbuk, 광주: gwangju,
        // 대구: daegu, 울산: ulsan, 인천: incheon, 전남: jeonnam, 전북: jeonbuk,
        // 제주: jeju, 충남: chungnam , 충북: chungbuk
        &.gangwon, &.incheon {
            background-color: var(--elements-orange);
        }
        &.gyeonggi, &.jeonnam {
            background-color: var(--elements-yellow);
        }
        &.gyeongnam, &.jeonbuk {
            background-color: var(--elements-green);
        }
        &.gyeongbuk, &.jeju {
            background-color: var(--elements-blue);
        }
        &.gwangju, &.chungnam {
            background-color: var(--elements-mint);
        }
        &.daegu, &.chungbuk {
            background-color: var(--elements-violet);
        }
        &.ulsan {
            background-color: var(--elements-peach);
        }
    }