.local-sort {
    flex-grow: 1;
    button {
        position: relative;
        width: 72px;
        height: 72px;
        background-color: var(--bg-light);
        color: var(--font-basic);
        font-weight: var(--font-weight-bold);
        cursor: pointer;
        
        &.active {
            background-color: var(--color-primary);
            color: var(--font-white);
            box-shadow: var(--shadow-2);
            
            &::before {
                content: '';
                position: absolute;
                top: 15px;
                left: 50%;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: var(--bg-white);
                transform: translateX(-50%);
            }
        }
    }
}