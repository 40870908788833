.breadcrumbs {
  padding: calc(var(--space-1) - 2px) 0;
  .home {
    position: relative;
    margin-left: auto;
    margin-right: calc(var(--space-2) + 2px);
    width: 24px;
    height: 24px;
    background: url('../../../assets/images/icon/icon-home.svg');
    font-size: 0;
    text-indent: -9999rem;
  }
  .button-wrap {
    position: relative;

    span {
      position: relative;
      width: fit-content;
      color: var(--font-basic);
      text-align: center;
      display: inline-block;
      padding-left: calc(var(--space-8) + 2px);
      padding-right: calc(var(--space-3) - 2px);

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 24px;
        height: 24px;
        background-image: url('../../../assets/images/icon/icon-down.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        transform: translateY(-50%) rotate(-90deg);
        transition: all 500ms ease-in-out;
      }
    }
  }
  button {
    position: relative;
    margin: 0 calc(var(--space-5) - 2px);
    width: 148px;
    color: var(--font-basic);
    text-align: left;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 24px;
      height: 24px;
      background-image: url('../../../assets/images/icon/icon-down.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateY(-50%);
      transition: all 500ms ease-in-out;
    }
    &:focus,
    .active {
      &::after {
        transform: rotate(180deg) translateY(50%);
      }
      & + ul {
        display: block;
      }
    }
  }
  ul {
    display: none;
    overflow: hidden;
    position: absolute;
    top: var(--space-10);
    left: 0;
    width: 100%;
    border: 1px solid var(--border-light);
    border-radius: 0 0 var(--space-2) var(--space-2);
    background-color: var(--bg-white);
    z-index: 2;

    li {
      a {
        display: block;
        padding: var(--space-3);
        color: var(--font-light);

        &:hover,
        &:focus {
          background-color: var(--bg-light);
          color: var(--font-basic);
        }
        &.selected {
          color: var(--color-primary);
        }
      }
    }
  }
}
