.error-page-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .home-btn {
        min-width: 247px;
        height: 68px;
        text-align: center;
        background: var(--bg-darkness);

        font-size: var(--font-size-4);
        font-weight: var(--font-weight-bold);
        line-height: calc(var(--default-font-size) * 1.75);
    }
}
