.page-title {
  position: relative;
  margin-bottom: var(--space-12);
  padding-left: var(--space-16);
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  &.school {
    &::before {
      background-image: url('../../../assets/images/icon/icon-school.svg');
    }
  }
  &.local {
    &::before {
      background-image: url('../../../assets/images/icon/icon-local.svg');
    }
  }
  &.privacy {
    &::before {
      background-image: url('../../../assets/images/icon/icon-privacy.svg');
    }
  }
  &.star {
    &::before {
      background-image: url('../../../assets/images/icon/icon-star.svg');
    }
  }
  &.bubble {
    &::before {
      background-image: url('../../../assets/images/icon/icon-bubble.svg');
    }
  }
  &.event {
    &::before {
      background-image: url('../../../assets/images/icon/icon-event.svg');
    }
  }
  &.education {
    &::before {
      background-image: url('../../../assets/images/icon/icon-edu.svg');
    }
  }
}
