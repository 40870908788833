.privacy-policy {
  color: var(--font-basic);
  h4 {
    margin-bottom: 24px;
  }
  .dots {
    li,
    dd {
      position: relative;
      padding-left: var(--space-4);
      margin-left: var(--space-2);
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        transform: translateY(-50%);
        background-color: var(--font-basic);
      }
    }
    a {
      color: var(--font-basic);
    }
  }
  .bold {
    font-weight: var(--font-weight-bold);
  }
  hr {
    margin-top: var(--space-12);
    margin-bottom: var(--space-12);
    border-top: 1px solid var(--border-light);
  }
  strong {
    &.body2 {
      display: block;
      margin-bottom: var(--space-6);
    }
  }
  .roundbox {
    margin: 0;
    padding: var(--space-9);
    background-color: var(--bg-light);
    border-radius: var(--radius-4);
  }
  ol {
    margin: 0 var(--space-6);
    span {
      display: block;
      margin-left: var(--space-5);
    }
  }
  dl {
    margin: 0 var(--space-6);
    dt {
      margin-top: var(--space-5);
    }
  }
  .info {
    span {
      margin-left: 0;
    }

    > li {
      &:nth-of-type(n + 2) {
        margin-top: var(--space-8);
      }
    }
  }
  .info-items {
    display: flex;
    align-items: center;
    gap: var(--space-2);
    margin-top: var(--space-3);
    padding: var(--space-6) var(--space-9);
    li {
      flex: 1 1 40%;
      margin-top: 0;
    }
  }
}
