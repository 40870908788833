.radix-themes {
    .rt-BaseCheckboxRoot {
        cursor: pointer;
        svg {
            fill: inherit;
        }
        &[data-state='checked'],
        &[data-state='indeterminate'] {
            &::before {
                background-color: var(--color-surface);
                box-shadow: inset 0 0 0 1px var(--gray-a7);
            }
        }
    }
}
