.main-slider {
  position: relative;
  .swiper {
    padding-top: 5px;
  }

  .utility {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: var(--space-12);
    margin-bottom: var(--space-17);
  }

  .swiper-pagination {
    margin-right: var(--space-3);
    color: var(--font-color-light); //var(--border-basic);
    font-size: var(--font-size-5);
    line-height: calc(var(--default-font-size) * 2);
    text-align: center;
  }

  .swiper-pagination-current {
    color: var(--font-darken);
    font-weight: var(--font-weight-bold);
  }

  .progress-wrap {
    position: relative;
    width: 32px;
    height: 32px;

    button {
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 0;
      width: 32px;
      height: 32px;
      border: 0;
      background-color: inherit;
      background-image: url('../../../assets/images/icon/icon-stop.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 12px 12px;
      font-size: 0;
      transform: translate(-50%, -50%);
      cursor: pointer;
      z-index: 10;

      &.play {
        background-image: url('../../../assets/images/icon/icon-play.svg');
      }

      &.pause {
        background-image: url('../../../assets/images/icon/icon-stop.svg');
      }
    }
  }

  .progress {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    color: var(--font-basic);
    font-weight: var(--font-weight-bold);
    z-index: 10;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 2px solid var(--border-light);
      border-radius: 50%;
    }
  }

  .progress svg {
    --progress: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    stroke-width: 2px;
    stroke: var(--border-darken);
    fill: none;
    stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
    z-index: 10;
  }

  .slider-prev,
  .slider-next {
    width: 48px;
    height: 24px;
    background-color: transparent;
    background-image: url('../../../assets/images/icon/icon-arrow-button.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 0;
    cursor: pointer;
  }

  .slider-prev {
    margin-right: var(--space-7);
    transform: rotateY(180deg);
  }

  .slider-next {
    margin-left: var(--space-7);
  }

  .swiper-button-disabled {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
  }

  .banner {
    width: 784px;
    height: 372px;
    background-image: url('../../../assets/images/common/img-banner-orange.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    // 강원: gangwon, 경기: gyeonggi, 경남: gyeongnam, 경북: gyeongbuk, 광주: gwangju,
    // 대구: daegu, 울산: ulsan, 인천: incheon, 전남: jeonnam, 전북: jeonbuk,
    // 제주: jeju, 충남: chungnam , 충북: chungbuk
    &.gangwon,
    &.incheon {
      background-image: url('../../../assets/images/common/img-banner-orange.svg');
    }

    &.gyeonggi,
    &.jeonnam {
      background-image: url('../../../assets/images/common/img-banner-yellow.svg');
    }

    &.gyeongnam,
    &.jeonbuk {
      background-image: url('../../../assets/images/common/img-banner-green.svg');
    }

    &.gyeongbuk,
    &.jeju {
      background-image: url('../../../assets/images/common/img-banner-blue.svg');
    }

    &.gwangju,
    &.chungnam {
      background-image: url('../../../assets/images/common/img-banner-mint.svg');
    }

    &.daegu,
    &.chungbuk {
      background-image: url('../../../assets/images/common/img-banner-violet.svg');
    }

    &.ulsan {
      background-image: url('../../../assets/images/common/img-banner-peach.svg');
    }

    .chips {
      &.local {
        position: absolute;
        top: 16px;
        right: 16px;
        font-weight: var(--font-weight-bold);
      }
    }

    .image-wrap {
      overflow: hidden;
      position: absolute;
      bottom: 48px;
      right: 113px;
      width: 198px;
      height: 280px;
      border-radius: var(--radius-4) var(--radius-4) 0 0;
      box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);

      &.noData {
        background: #f8f8f8 url('../../../assets/images/common/no-image.png') no-repeat center center;
      }

      img {
        width: 100%;
        height: 100%;
        // object-fit: cover;
      }
    }

    .slide-item {
      display: flex;
      // flex-direction: column;
      // align-items: flex-start;
      height: 100%;
      width: 100%;
    }
    .text-wrap {
      padding-top: var(--space-14);
      padding-bottom: var(--space-12);
      padding-left: var(--space-12);
      width: 400px;
      color: var(--font-darken);

      p {
        margin-top: auto;
        font-weight: var(--font-weight-bold);
      }
    }
  }
}

.partner-slider {
  position: relative;

  .swiper {
    width: 1003px;
    margin: 0 var(--space-14);
  }

  .slider-item {
    overflow: hidden;
    width: 194px;
    height: 80px;
    border: 1px solid var(--border-light);
    border-radius: var(--space-3);
    text-align: center;
    padding: 5px;
    display: flex;
    justify-content: center;
    > a {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-height: 70px;
      }
    }
  }

  .slider-prev,
  .slider-next {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    background-color: inherit;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 0;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
  }

  .slider-prev {
    left: 0;
    background-image: url('../../../assets/images/icon/icon-circle-chevron-left.svg');
  }

  .slider-next {
    right: 0;
    background-image: url('../../../assets/images/icon/icon-circle-chevron-right.svg');
  }

  .swiper-button-disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

.sub-slider {
  position: relative;

  .swiper {
    margin-left: var(--space-8);
    margin-right: calc(var(--space-8) + 2px);
    width: 820px;
    height: 480px;
    border-radius: var(--radius-4);
    overflow: hidden;
  }

  .swiper-slide {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .slider-prev,
  .slider-next {
    position: absolute;
    top: 50%;
    width: 68px;
    height: 68px;
    background-color: inherit;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 0;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
  }

  .slider-prev {
    left: 0;
    background-image: url('../../../assets/images/icon/icon-circle-arrow-right.svg');
    transform: translateY(-50%) rotate(180deg);
  }

  .slider-next {
    right: 0;
    background-image: url('../../../assets/images/icon/icon-circle-arrow-right.svg');
  }

  .swiper-button-disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  .swiper-pagination-bullets {
    display: flex;
    justify-content: center;
    gap: var(--space-1);
    position: absolute;
    bottom: var(--space-9);
    left: auto;
    width: 100%;
    z-index: 1;
  }

  .swiper-pagination-bullet {
    width: 35px;
    height: 6px;
    border-radius: var(--radius-1);
    background-color: var(--bg-white);
    opacity: 0.4;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
  }
}

.detail-slider {
  position: relative;

  .swiper {
    width: 100%;
    max-height: 813px;
  }

  .swiper-slide {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .swiper-pagination {
    display: flex;
    align-items: baseline;
    gap: var(--space-2);
    position: absolute;
    bottom: var(--space-5);
    left: 50%;
    transform: translateX(-50%);
    color: var(--font-white);
    z-index: 2;

    & > *:not(.swiper-pagination-current) {
      opacity: 0.64;
    }
  }

  .swiper-pagination-current {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-8);
    opacity: 1;
  }

  .arrow {
    position: absolute;
    top: 50%;
    background-color: #fff;
    cursor: pointer;
    z-index: 2;

    &.rt-r-size-2 {
      --base-button-height: var(--space-12);
    }

    &.slider-prev {
      left: 0;
      transform: translate(-50%, -50%);

      &::before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background-image: url(../../../assets/images/icon/icon-arrow-prev.svg);
      }
    }

    &.slider-next {
      right: 0;
      transform: translate(50%, -50%);

      &::before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background-image: url(../../../assets/images/icon/icon-arrow-next.svg);
      }
    }
  }
}

html[data-device='mobile'] {
  .detail-slider {
    .arrow {
      position: static;
      transform: none;
    }

    .swiper-pagination {
      position: static;
      transform: none;
      color: var(--font-basic);
    }

    .swiper-pagination-current {
      font-size: var(--font-size-6);
    }
  }
}
