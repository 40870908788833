.board-image-items {
  border-block-start: 2px solid var(--gray-12);
  gap: var(--space-6);
  padding-block-start: var(--space-5);

  .board-card {
    border: 1px solid var(--border-light);
    cursor: pointer;
    &:hover {
      border-color: var(--border-darken);
    }
    a {
      display: block;
      width: 100%;
    }
  }

  &.type-list {
    .board-card {
      display: flex;
      gap: var(--space-9);
      height: 284px;
      padding-block: var(--space-9);
      padding-inline: var(--space-8);
      .image-box {
        flex-shrink: 0;
        width: 150px;
        height: 212px;
        margin: 0;
        border-radius: 0;
      }
    }
    .card-box {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
    .card-head {
      margin-block-end: auto;
      padding-inline-end: var(--space-15);
    }
    .card-footer {
      padding-block-start: var(--space-4);
    }
  }

  &.type-thumbnail {
    .board-card {
      padding: 0;
      .image-box {
        height: 168px;
        margin: 0;
      }
    }
    .card-box {
      padding: var(--space-7);
    }
    .card-footer {
      padding-block-start: var(--space-5);
    }
  }

  &.type-poster {
    .board-card {
      padding: 0;
      .image-box {
        position: relative;
        height: 236px;
        margin: 0;
        padding-block-start: var(--space-6);
        padding-inline: var(--space-7);
        text-align: center;
        background-color: var(--bg-light);
        background-size: cover;
        &::before {
          content: '';
          position: absolute;
          display: inline-block;
          width: 100%;
          height: 100%;
          vertical-align: middle;
          backdrop-filter: blur(5px) grayscale(0.5) brightness(0.8);
          left: 0;
          top: 0;
        }
        img {
          position: relative;
          width: 150px;
          height: 212px;
          object-fit: cover;
          border-top-left-radius: var(--space-4);
          border-top-right-radius: var(--space-4);
        }
      }
    }
    .card-box {
      border-block-start: 1px solid var(--border-light);
      padding: var(--space-7);
    }
    .card-footer {
      padding-block-start: var(--space-5);
    }
  }

  .board-card {
    &::before,
    &::after {
      display: none;
    }
    .image-box {
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .board-extra {
    position: absolute;
    top: var(--space-3);
    right: var(--space-3);
  }

  .card-body {
    max-width: 100%;
    margin-block-start: var(--space-5);
    .icon-clip {
      display: flex;
      align-items: center;
      &::before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-inline-end: var(--space-2);
        background-image: url(../../../assets/images/icon/icon-clip.svg);
      }
    }
  }

  .card-footer {
    margin-block-start: var(--space-5);
    border-block-start: 1px dashed var(--border-light);
  }

  .view {
    display: flex;
    align-items: center;
    margin-inline-start: auto;
    //   {/* [웹접근성] 조회수 아이콘 before -> img 태그로 변경 */}
    // &::before {
    //   content: '';
    //   display: inline-block;
    //   width: 24px;
    //   height: 24px;
    //   margin-inline-end: var(--space-1);
    //   background-image: url('../../../assets/images/icon/icon-view.svg');
    // }
    .view-count {
      margin-left: 4px;
    }
  }

  .no-image {
    width: 100%;
    height: 236px;
    background: #f8f8f8 url('../../../assets/images/common/no-image.png') no-repeat center center;
    &.type-thumbnail {
      height: 168px;
    }
  }
  .fix-2line {
    height: 64px;
  }
}
