.promotion-system-wrap {
  .system-box {
    span {
      color: var(--sw-dark-blue);
    }
  }
  .role-box {
    min-height: 208px;
    border: 1px solid var(--border-light);
    border-radius: var(--radius-4);

    .dots {
      li {
        text-indent: -10px;
        padding-left: var(--space-3);
        margin-bottom: var(--space-2);
        color: var(--font-basic);
        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          content: '';
          display: inline-block;
          width: var(--space-2);
          height: var(--space-2);
          border-radius: 100%;
          margin-bottom: calc(var(--space-1) - 2px);
          margin-right: var(--space-2);
          background-color: var(--elements-blue);
        }
      }
    }

    .dash {
      margin-top: var(--space-1);
      li {
        padding-left: var(--space-4);
        margin-bottom: 0;
        &::before {
          display: none;
        }
      }
    }
  }
}
