.introduction {
  h4 {
    margin-bottom: 24px;
    + p {
      color: var(--font-basic);
      span {
        color: var(--blue-9);
      }
    }
  }

  .roundbox {
    overflow: hidden;
    border-radius: var(--radius-4);

    &.bg-gray {
      background-color: var(--bg-light);
    }

    .image-wrap {
      width: 400px;
      height: 205px;
    }
  }

  .text-wrap {
    overflow: hidden;
    padding: var(--space-8);
    width: 400px;
    height: 100%;
    border-radius: 0 0 var(--space-4) var(--space-4);
    border: 1px solid var(--border-light);
    word-break: keep-all;
  }

  strong {
    display: block;
    margin-bottom: var(--space-6);
  }

  .dots {
    // 기본 center
    li {
      position: relative;
      padding-left: var(--space-4);
      margin-bottom: var(--space-6);
      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: var(--elements-blue);
      }

      &.dots-bg-red {
        &::before {
          background-color: var(--red-9);
        }
      }
      &.dots-bg-green {
        &::before {
          background-color: var(--sw-green);
        }
      }
      &.dots-bg-blue {
        &::before {
          background-color: var(--sw-light-blue);
        }
      }
    }
  }

  .performance-wrap {
    .performace-item-head {
      color: var(--font-color-white);
      word-break: keep-all;
      padding: var(--space-3) var(--space-4);
      border-radius: var(--radius-2);

      &:first-child {
        background-color: var(--sw-dark-blue);
      }
      &:nth-child(2) {
        background-color: var(--sw-orange);
      }
      &:nth-child(3) {
        background-color: var(--sw-yellow);
      }
      &:nth-child(4) {
        background-color: var(--sw-green);
      }
      &:nth-child(5) {
        background-color: var(--sw-light-blue);
      }
    }

    .performace-item-body {
      padding: var(--space-6) var(--space-4);
      border-bottom: 1px solid var(--border-light);

      .year {
        color: var(--sw-dark-blue);
      }
      .local {
        color: var(--red-9);
      }
      .edu {
        color: var(--sw-yellow);
      }
      .person {
        color: var(--sw-green); //var(--sw-light-green);
      }
      .dots {
        li {
          color: var(--font-basic);
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        span {
          color: var(--font-darken);
        }
      }
    }
  }

  .promotion-wrap {
    .promotion-item {
      .icon-wrap {
        width: 386px;
        height: 238px;
        margin-bottom: var(--space-10);
        text-align: center;
      }

      strong {
        padding-top: var(--space-4);
        border-top: 1px solid var(--border-light);
      }

      p,
      span,
      li {
        color: var(--font-basic);
        word-break: keep-all;
      }

      .dots {
        li {
          margin-bottom: var(--space-3);
          &:last-child {
            margin-bottom: 0;
          }
          &::before {
            top: 9px;
          }
        }
      }
    }
  }
}
